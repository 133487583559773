import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { groupBy } from 'lodash';

import {
  failSnackBottomConstantConfig,
  getFileNameFromHttpHeaders,
  isRealValue,
  ProcedureTemplateTypes,
  somethingWentWrong,
} from '@tr-common';

import {
  AllIDs,
  checkAndLoadRelevantIDScript,
  defineIfErrorHasAbsentAnswers,
  getErrorWithAbsentAnswers,
  getParentQuestions,
  LoadFullStudySourcesType,
  ParamsIDs,
  participantIsFromJAEBFilter,
  saveAbsentQuestions,
} from '../../models';
import { SurveyPdfService } from '../../services/survey-pdf.service';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import {
  defineEligibleDialog,
  finishSubmitPending,
  getPDFOfSubmittedAnswers,
  getPDFOfSubmittedAnswersFail,
  getPDFOfSubmittedAnswersSuccess,
  getSubmittedAnswers,
  getSubmittedAnswersFail,
  getSubmittedAnswersSuccess,
  loadAnswers,
  loadStudyFull,
  loadStudyFullStage2,
  navigateToQuestionProcedure,
  nextProcedure,
  redirectToProcedureStatus,
  refreshHeaderWithProcedures,
  refreshStudyQuestions,
  refreshStudyQuestionsFail,
  refreshStudyQuestionsSuccess,
  selectActiveProcedureQuestions,
  selectNextActiveProcedure,
  selectParamsIDs,
  selectParticipantDetails,
  selectStudy,
  selectUrlProcedure,
  showConclusionDialog,
  startRefreshPending,
  storeRelevantIDResponse,
  submitConsentProcedureForcibly,
  submitProcedure,
  submitProcedureAbsentAnswers,
  submitProcedureFail,
  submitProcedureSuccess,
} from '../index';
import { ScriptService } from '../../../services';

// noinspection JSUnusedGlobalSymbols
declare global {
  interface Window {
    callCSONow: () => void;
    CSOResponseComplete: () => void;
    CSONoResponse: () => void;
    CSOTimeoutHandle: ReturnType<typeof setTimeout>;
     // RelevantID Service
     relevantID?: {
      callService: (params: any, callback: (responseData: any) => void) => void;
    };
  }
}
@Injectable()
export class ProcedureSubmitEffects {
  actions$ = new Subject<Action>();

  refreshStudyQuestions$ = createEffect(() => this.actions$.pipe(
    ofType(refreshStudyQuestions),
    withLatestFrom(
      this.store.select(selectParticipantDetails),
      this.store.select(selectParamsIDs)
    ),
    switchMap(([, {profile_type}, {studyID}]) => this.surveyService.loadQuestions(profile_type, studyID).pipe(
      map(response => refreshStudyQuestionsSuccess({payload: groupBy(response, 'procedure')})),
      catchError(({error}) => of(refreshStudyQuestionsFail({error})))
    ))
  ));

  submitProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedure),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{id, userID, hasConclusionPopup}, {userStudyID}]) => this.surveyService.submitProcedure(userID, userStudyID, id).pipe(
      tap(() => !hasConclusionPopup && this.store.dispatch(finishSubmitPending())),
      map(payload => submitProcedureSuccess({payload})),
      catchError(({error}) => of(defineIfErrorHasAbsentAnswers(error)
        ? submitProcedureAbsentAnswers({error, procedure: id})
        : submitProcedureFail({error})
      )),
    )),
  ));

  submitConsentProcedureForcibly$ = createEffect(() => this.actions$.pipe(
    ofType(submitConsentProcedureForcibly),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([, {userStudyID, userID, studyID}]) => this.surveyService.submitProcedure(
      userID, userStudyID, ProcedureTemplateTypes.consent
    ).pipe(
      concatMap(() => [finishSubmitPending(), navigateToQuestionProcedure({studyID, userStudyID, userID})]),
      catchError(({error}) => of(submitProcedureFail({error})))
    ))
  ));

  navigateToQuestionProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToQuestionProcedure),
    tap(({userStudyID, userID, studyID}) => {
      this.surveyRouter.navigateToQuestionProcedure({studyID, userStudyID, userID});
    })
  ), {dispatch: false});

  redirectToProcedureStatus$ = createEffect(() => this.actions$.pipe(
      ofType(redirectToProcedureStatus),
      withLatestFrom(this.store.select(selectParamsIDs)),
      tap(([{procedure}, paramsIDs]) => void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure))
  ), {dispatch: false});

  submitProcedureFail$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureFail),
    tap(({error}) => this.snackBar.open(error.toString(), 'X', failSnackBottomConstantConfig())),
    map(() => finishSubmitPending())
  ));

  submitProcedureAbsentAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureAbsentAnswers),
    withLatestFrom(this.store.select(selectActiveProcedureQuestions)),
    map((
      [{procedure, error}, questions]
    ) => ({procedure, absentQuestions: getParentQuestions(getErrorWithAbsentAnswers(error), questions)})),
    tap(({absentQuestions}) => saveAbsentQuestions(absentQuestions)),
    // tap((data) => console.log({handler: 'submitProcedureAbsentAnswers$', ...data, storage: sessionStorage.getItem(absentAnswerKey)})),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectParticipantDetails)),
    tap(([{procedure, absentQuestions: [questionID]}, paramsIDs]) => void this.surveyRouter.navigateToQuestionFromSubmit(paramsIDs, procedure, questionID)),
    concatMap(([, {userID, studyID, userStudyID}, participant]) => [
      finishSubmitPending(),
      startRefreshPending(),
      loadAnswers({userStudyID, participantID: userID}),
      loadStudyFullStage2({userID, studyID, userStudyID, sourceType: 'status', participant})
    ])
  ));

  submitProcedureSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureSuccess),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectNextActiveProcedure),
      this.store.select(selectUrlProcedure),
      this.store.pipe(participantIsFromJAEBFilter()),
      this.store.select(selectStudy)
    ),
    tap(([{payload}, {userID, studyID, userStudyID}, upcomingProcedure, procedure,  isJaeb, study]) => {
      const procedureId = (isRealValue(upcomingProcedure) && isJaeb) ? upcomingProcedure.id : procedure;
      const paramsIDs: ParamsIDs = {userID, studyID, userStudyID};
      const props = {...paramsIDs, sourceType: procedure as LoadFullStudySourcesType};
      const isEligible = payload.user_study_is_eligible;
      const imperiumClientID = this.surveyService.imperiumClientId;
      //console.log('>>impID', imperiumClientID)
      switch (procedure) {
        case ProcedureTemplateTypes.intro:
          this.store.dispatch(finishSubmitPending());
          this.store.dispatch(refreshHeaderWithProcedures());
          this.store.dispatch(nextProcedure());
          break;
        case ProcedureTemplateTypes.screening:
        // check eligibility only after screening submit
        this.store.dispatch(isEligible ? defineEligibleDialog({ payload: paramsIDs }) : loadStudyFull(props));
        void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure);
         
          if (typeof window.callCSONow === 'function' && study.study_type === "core_study" && !isEligible && imperiumClientID) {
            let form = document.getElementById('CSO-imp-form') as HTMLFormElement;
            if (!form) {
              form = document.createElement('form');
              form.id = 'CSO-imp-form';
              form.style.display = 'none';
              document.body.appendChild(form);
            }
          
            this.addOrUpdateHiddenInput(form, 'Survey', paramsIDs.studyID);
            this.addOrUpdateHiddenInput(form, 'ClientId', imperiumClientID);
            this.addOrUpdateHiddenInput(form, 'StatusFlag', isEligible ? 'C' : 'S');
            this.addOrUpdateHiddenInput(form, 'PanelistID', paramsIDs.userID);
            this.addOrUpdateHiddenInput(form, 'CSOCompleted', '0');
          
            window.CSOResponseComplete = () => {
              const completedField = document.getElementById('CSOCompleted') as HTMLInputElement;
              if (completedField) completedField.value = '1';
              //void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure);
            };
          
            window.CSONoResponse = () => {
              const completedField = document.getElementById('CSOCompleted') as HTMLInputElement;
              if (completedField && completedField.value === '0') {
               //void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure);
              }
            };
          
            if (window.CSOTimeoutHandle) {
              clearTimeout(window.CSOTimeoutHandle);
            }
          
            window.CSOTimeoutHandle = setTimeout(() => window.CSONoResponse(), 3000);
          
            window.callCSONow();
          
          }
          
          break;
        case ProcedureTemplateTypes.consent:
          this.store.dispatch(finishSubmitPending());
          void this.surveyRouter.navigateToProcedurePreservingParams(
            {...props, isStatus: !isJaeb, procedureId}
          );
          this.store.dispatch(loadStudyFull(props));
          break;
        default: // ProcedureTemplateTypes.questionnaire and so on
          if (payload.procedure_id === procedure) {
            this.store.dispatch(showConclusionDialog());
          }      

          this.store.dispatch(finishSubmitPending());
          this.store.dispatch(loadStudyFull(props));
          if (payload.procedure_id === ProcedureTemplateTypes.questionnaire && study.study_type === "core_study") {
            this.surveyService.getDataInsightsChartsData().subscribe();
          }
          //check for imperium client id
          if(study.study_type === "core_study" && imperiumClientID && procedure === ProcedureTemplateTypes.questionnaire) {
            if (typeof window.callCSONow === 'function' && isEligible) {
              let form = document.getElementById('CSO-imp-form') as HTMLFormElement;
              if (!form) {
                form = document.createElement('form');
                form.id = 'CSO-imp-form';
                form.style.display = 'none';
                document.body.appendChild(form);
              }
            
              this.addOrUpdateHiddenInput(form, 'Survey', paramsIDs.studyID);
              this.addOrUpdateHiddenInput(form, 'ClientId', imperiumClientID);
              this.addOrUpdateHiddenInput(form, 'StatusFlag', 'C');
              this.addOrUpdateHiddenInput(form, 'PanelistID', paramsIDs.userID);
              this.addOrUpdateHiddenInput(form, 'CSOCompleted', '0');
            
              window.CSOResponseComplete = () => {
                const completedField = document.getElementById('CSOCompleted') as HTMLInputElement;
                if (completedField) completedField.value = '1';
                // proceed with relevantID service
                this.proceedWithRelevantID(paramsIDs, procedure, props, imperiumClientID, payload, study);
              };
            
              window.CSONoResponse = () => {
                const completedField = document.getElementById('CSOCompleted') as HTMLInputElement;
                if (completedField && completedField.value === '0') {
                  // proceed with relevantID service
                  this.proceedWithRelevantID(paramsIDs, procedure, props, imperiumClientID, payload, study);
                }
              };
            
              if (window.CSOTimeoutHandle) {
                clearTimeout(window.CSOTimeoutHandle);
              }
              window.CSOTimeoutHandle = setTimeout(() => window.CSONoResponse(), 3000);
              window.callCSONow();
            } else {
              console.log('Proceed to relevant ID if CSO not available')
              // proceed with relevantID if CSO service not available
              this.proceedWithRelevantID(paramsIDs, procedure, props, imperiumClientID, payload, study);
            }
             
          } else {
            // proceed with existing flow
            // this.store.dispatch(finishSubmitPending());
            // this.store.dispatch(loadStudyFull(props));
            // if (payload.procedure_id === ProcedureTemplateTypes.questionnaire && study.study_type === "core_study") {
            //   this.surveyService.getDataInsightsChartsData().subscribe();
            // }
            void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure, procedure === ProcedureTemplateTypes.questionnaire);
          }
          break;
      }
    }),
  ), {dispatch: false});

  getSubmittedAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(getSubmittedAnswers),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectUrlProcedure)),
    map(([, {userStudyID, userID}, procedure]: [unknown, AllIDs, string]) => ({userStudyID, userID, procedure})),
    switchMap(({userStudyID, userID, procedure}) => this.surveyPdf.loadHTMLVersion(userID, userStudyID, procedure).pipe(
      map(payload => getSubmittedAnswersSuccess({payload})),
      catchError(({error}) => of(getSubmittedAnswersFail({error})))
    ))
  ));

  getPDFOfSubmittedAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(getPDFOfSubmittedAnswers),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectUrlProcedure)),
    map((
      [{toPrint}, {userStudyID, userID}, procedure]: [{toPrint: boolean}, AllIDs, string]
    ) => ({userStudyID, userID, procedure: procedure ?? ProcedureTemplateTypes.screening, toPrint})),
    switchMap(({toPrint, userStudyID, userID, procedure}) => this.surveyPdf.loadPDFVersion(userID, userStudyID, procedure).pipe(
      map(({body, headers}: HttpResponse<Blob>) => ({payload: body, filename: getFileNameFromHttpHeaders(headers)})),
      map(({payload, filename}) => getPDFOfSubmittedAnswersSuccess({payload, filename, toPrint})),
      catchError(({error}) => of(getPDFOfSubmittedAnswersFail({error})))
    ))
  ));

  getPDFOfSubmittedAnswersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getPDFOfSubmittedAnswersSuccess),
    tap(({payload, filename, toPrint}) => toPrint
      ? this.surveyPdf.printPDFVersion(payload)
      : this.surveyPdf.downloadPDFVersion(filename, payload)
    )
  ), {dispatch: false});

  storeRelevantIDResponse$ = createEffect(() => this.actions$.pipe(
    ofType(storeRelevantIDResponse),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{ data, isResponseAvailable, failureReason }, { userStudyID, userID, studyID }]) => 
      this.surveyService.storeRelevantIDResponse(userID, userStudyID, studyID, data, isResponseAvailable, failureReason).pipe(
        catchError(() => EMPTY) // Ignore errors
      )
    )
  ), { dispatch: false }); // No action dispatched from the effect
  

  getFail$ = createEffect(() => this.actions$.pipe(
    ofType(getSubmittedAnswersFail, getPDFOfSubmittedAnswersFail, refreshStudyQuestionsFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig())),
  ), {dispatch: false});

  private addOrUpdateHiddenInput(form: HTMLFormElement, name: string, value: string): void {
    let input = document.getElementById(name) as HTMLInputElement;
    if (!input) {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.id = name;
      form.appendChild(input);
    }
    input.value = value;
  }

  private callRelevantIDService(paramsIDs: any, procedure: any, props: any, imperiumClientID: string, payload: any, study: any): void {
  
    const relevantIDTimeout = setTimeout(() => {
      console.error('RelevantID service timed out after 8 seconds');
      this.store.dispatch(storeRelevantIDResponse({isResponseAvailable: false, data: null, failureReason: "RelevantID service timed out"}));
      this.proceedNextToFinishStudy(paramsIDs, procedure, props, payload, study);
    }, 8000);
  
    window.relevantID?.callService(
      {
        ClientID: imperiumClientID,
        SurveyID: paramsIDs.studyID,
        PanelistID: paramsIDs.userID,
        GeoCodes: '1,US',
        RequestCSOData: '1',
      },
      (responseData: any) => {
        clearTimeout(relevantIDTimeout);
        this.store.dispatch(storeRelevantIDResponse({isResponseAvailable: true, data: responseData}));
        this.proceedNextToFinishStudy(paramsIDs, procedure, props, payload, study);
      }
    );
  }

  private proceedWithRelevantID(paramsIDs: any, procedure: any, props: any, imperiumClientID: string, payload: any, study: any): void {
    checkAndLoadRelevantIDScript(imperiumClientID, this.scripts)
      .pipe(first())
      .subscribe({
          next: () => {
            if (typeof window.relevantID?.callService === 'function' && procedure === ProcedureTemplateTypes.questionnaire) {
              this.callRelevantIDService(paramsIDs, procedure, props, imperiumClientID, payload, study);
            } else {
              console.error('RelevantID service is not available');
              this.store.dispatch(storeRelevantIDResponse({isResponseAvailable: false, data: null, failureReason: "Error loading RelevantID call service"}));
              this.proceedNextToFinishStudy(paramsIDs, procedure, props, payload, study);
            }
          },
          error: (err) => {
            console.error('Error loading RelevantID script', err);
            this.store.dispatch(storeRelevantIDResponse({isResponseAvailable: false, data: null, failureReason: "Error loading RelevantID script"}));
            this.proceedNextToFinishStudy(paramsIDs, procedure, props, payload, study);
          }
    });
    
  }

  private proceedNextToFinishStudy(paramsIDs: any, procedure: any, props: any, payload: any, study: any): void {
    // this.store.dispatch(finishSubmitPending());
    // this.store.dispatch(loadStudyFull(props));
    // if (payload.procedure_id === ProcedureTemplateTypes.questionnaire && study.study_type === "core_study") {
    //   this.surveyService.getDataInsightsChartsData().subscribe();
    // }
    void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure, procedure === ProcedureTemplateTypes.questionnaire);
  }

  constructor(
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public surveyService: SurveyService,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private surveyPdf: SurveyPdfService,
    private scripts: ScriptService
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
